import React, {useCallback} from 'react'
import styles from './CardImage.module.scss'


export const CardImage = ({item, isImageOpen, index}) => {

    const cardClick = useCallback(() => {
        isImageOpen(item)
    }, [item, isImageOpen])

    return (
        <li className={styles.item}  onClick={cardClick}>
            <div className={styles.box_image}>
                <img className={styles.image} src={item[0].image} alt={item[0].title}/>
            </div>
            <p className={styles.text}>{item[0].title}</p>
        </li>
    )
}