import React, { useCallback, useState, useEffect, useRef } from 'react'
import emailjs from 'emailjs-com'
import styles from './Discount.module.scss'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"

export const Discount = ({popupOpen}) => {

    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
        console.log(handleForm.values);
            emailjs.sendForm('service_bdc4zu7', 'template_pdi3upc', form.current, 'fsihkY42yKl1vDCOa')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )

    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
            <div className={styles.box}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Хотите записаться на визит к нам?</h2>
                    <p className={styles.text}>Оставьте свои данные. Мы свяжемся с вами в течение нескольких минут и запишем Вас на удобную дату!</p>
                    <form className={styles.form} onSubmit={sendData} ref={form}>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите имя
                                    <input
                                        className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                        type='text' 
                                        name='name'
                                        minLength='2'
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange} 
                                        pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                        required/>
                                </label>
                            </div>
                            <span className={styles.input_error}>{handleForm.errors.name}</span>
                            <div className={styles.box_input}>
                                <label className={styles.label}>Введите телефон       
                                        <InputMask
                                        className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                        type='tel' 
                                        name='phone'
                                        mask={'+7\\(999) 999-99-99'}
                                        value={handleForm.values.this}
                                        onChange={handleForm.handleChange}  
                                        pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                        required/>
                                </label>
                            </div>            
                            <span className={styles.input_error}>{handleForm.errors.phone}</span>
                                    <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'>Отправить</button>
                        </form>
                </div>
            </div>
            </div>
        </section>
        
    )
}