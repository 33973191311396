import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='AboutUs'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>студии</span></h2>
                        <div className={styles.line}></div>
                        <p className={styles.text}>&#8195;Приветствуем тебя, наш клиент! Нас зовут – Юлия и Софья Дунаевы.</p>
                        <p className={styles.text}>&#8195;Мы всегда твердо знали, что создадим одну из лучших студий в городе, что в ней будет честный и открытый расчет с клиентами, самое лучшее качество услуг и невероятная атмосфера. Что работать мы будем на тех материалах, которыми пользуемся и восхищаемся сами.</p>
                        <p className={styles.text}>&#8195;Мы с радостью помогаем осуществлять мечты. Сделайте счастливее себя и приходите к нам, даже просто выпить чашечку кофе. И вы насладитесь главным — атмосферой тепла, внимания и доброты наполняющей нашу студию.</p>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}