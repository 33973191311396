import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Services/image_1.webp'
import icon_2 from '../../image/Services/image_2.webp'
import icon_3 from '../../image/Services/image_3.webp'
import icon_4 from '../../image/Services/image_4.webp'
import icon_5 from '../../image/Services/image_5.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Services = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Наши услуги</motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_1} alt='девушка'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Маникюр</h3>
                                <p className={styles.item_text}>Маникюр аппаратный/комбинированный, стойкое покрытие, укрепление, наращивание, дизайн.</p>
                                <a className={styles.button} href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_2} alt='девушка'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Педикюр</h3>
                                <p className={styles.item_text}>Smart педикюр, стойкое покрытие, дизайн. Массаж стоп, увлажнение.</p>
                                <a className={styles.button} href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.item_image}  src={icon_3} alt='девушка'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Брови</h3>
                                <p className={styles.item_text}>Оформление, окрашивание краской/хной, долговременная укладка, восстановление бровей</p>
                                <a className={styles.button} href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_4} alt='девушка'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Ресницы</h3>
                                <p className={styles.item_text}>Наращивание ресниц, ламинирование ресниц, ботокс, вельвет ресниц, окрашивание. Cделай взгляд более выразительным!</p>
                                <a className={styles.button} href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_5} alt='девушка'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>брови + ресницы</h3>
                                <p className={styles.item_text}>Мастер одновременно работает и с ресницами и с бровями. Общее время сокращается. Вместе дешевле!</p>
                                <a className={styles.button} href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                            </div>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}