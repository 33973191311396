import React from 'react'
import { motion } from "framer-motion"
import styles from './Palette.module.scss'
import image from '../../image/Palette/img.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Palette = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Потрясающая палитра гель&#8205;-&#8205;лаков</h2>
                        <div className={styles.line}></div>
                        <p className={styles.text}>&#8195;Гель-лак - лучшее решение для стойкого маникюра. Не теряет глянцевый блеск на протяжении всей носки. Держится от двух до четырех недель. Это та особенность, за которую его ценят и любят.</p>
                        <a className={styles.link}  href="https://dikidi.net/#widget=79969"  target='_blank' rel='noopener noreferrer'>записаться</a>
                    </motion.div>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
            </div>
        </motion.section>
        
        
    )
}