import React from 'react'
import styles from './Secures.module.scss'
import { motion } from "framer-motion"
import icon from '../../image/Secures/image_1.svg'
import icon_1 from '../../image/Secures/image_2.svg'
import icon_2 from '../../image/Secures/image_3.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Secures = () => {


    return (
        <motion.section 
            className={styles.main} 
            id='Portfolio'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Стерильность для нас превыше всего</motion.h2>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <img className={styles.item_image}  src={icon} alt='иконка'/>
                        </div>
                        <h3 className={styles.item_title}>Дезинфекция</h3>
                        <p className={styles.item_text}>Все приборы, после использования, проходят обработку в специальном дезинфицирующем растворе</p>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <img className={styles.item_image}  src={icon_1} alt='иконка'/>
                        </div>
                        <h3 className={styles.item_title}>Стерилизация</h3>
                        <p className={styles.item_text}>Иструмент проходит стерилизацию в сухожаровом шкафу (обработка нагретым воздухом в термостойкой камере)</p>
                    </li>
                    <li className={styles.item}>
                        <div className={styles.item_box}>
                            <img className={styles.item_image}  src={icon_2} alt='иконка'/>
                        </div>
                        <h3 className={styles.item_title}>Крафт-пакеты</h3>
                        <p className={styles.item_text}>Крафт-пакет всегда вскрывается в присутствии клиента непосредственно перед началом процедуры</p>
                    </li>
                </motion.ul>
            </div>
        </motion.section>
    )
}