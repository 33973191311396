import React from 'react'
import styles from './Portfolio.module.scss'
import { motion } from "framer-motion"
import { arrPortfolio } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { CardImage } from '../CardImage/CardImage'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Portfolio = ({isImageOpen}) => {


    return (
        <motion.section 
            className={styles.main} 
            id='Portfolio'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Примеры <span>работ</span></motion.h2>
                <div className={styles.line}></div>
                <p className={styles.text}>Для просмотра всех работ кликните на нужный раздел</p>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    {arrPortfolio.map((item, index) =>{
                        const keyUid = uuidv4()
                            return(
                                <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen} 
                                />
                            ) 
                    })}
                </motion.ul>
            </div>
        </motion.section>
    )
}