import React from 'react'
import styles from './Main.module.scss'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { InitialPage } from '../../components/initialPage/InitialPage'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import {Advantages} from '../../components/Advantages/Advantages'
import { Services } from '../../components/Services/Services'
import { Prices } from '../../components/Prices/Prices'
import {Portfolio} from "../../components/Portfolio/Portfolio"
import icon from '../../image/Header/logo.png'
import {Discount} from '../../components/Discount/Discount'
import { Secures } from '../../components/Secures/Secure'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import { Training } from '../../components/Training/Training'
import { Palette } from '../../components/Palette/Palette'

export const Main = ({isImageOpen, popupOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage/>
            <AboutUs/>
            <SliderTechnologies/>
            <Services/>
            <Secures/>
            <Palette/>
            <Advantages/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Discount
                popupOpen={popupOpen}
            />
            <Prices/>
            <Training/>
            <SliderTechnologies/>
            <div className={styles.box_map}>
                    <YMaps>
                        <Map width='100%' height='100%' defaultState={{ center: [55.735099, 38.005725], zoom: 11}}>
                            <Placemark 
                                geometry={[55.735099, 38.005725]} 
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageSize: [40, 40],
                                        iconImageHref: icon,
                                        }}
                                    />
                        </Map>
                    </YMaps>
            </div>
        </section>
    )
}