import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"


export const InitialPage = () => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                    <div className={styles.item}>
                        <div className={styles.overlay}>
                            <div className={styles.box}>
                                <div className={styles.container}>
                                    <h1 className={styles.title}>Территория красоты и&#160;стиля</h1>
                                    <div className={styles.line}></div>
                                    <p className={styles.text}>Создание красоты — это искусство.</p>
                                    <a className={styles.link}  href="https://dikidi.net/#widget=79969"  rel='noopener noreferrer'>записаться</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
        </motion.section>
    )
}