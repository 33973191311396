import React, { useState } from 'react'
import styles from './Hint.module.scss'
import disactive from '../../image/Know/disactive.svg'

export  const Hint = ({children, title}) => {

    const [isActive, setIsActive] = useState(false);


    return (
    <div className={styles.hint} onClick={() => setIsActive(!isActive)}>
        <div className={styles.hint_container}>
            <div className={styles.hint_box}>
                <h3 className={styles.hint_title}>{title}</h3>
            </div>
            <img className={!isActive ? styles.hint_image : styles.hint_active} src={disactive} alt='галочка'  onClick={() => setIsActive(!isActive)}/>
        </div>
        {isActive && (<div className={styles.text}>
            {children}
        </div>)}
    </div>
    )
}