import React from 'react'
import styles from './Prices.module.scss'
import { Hint } from '../Hint/Hint'
import { arrManicure, arrPedikyur, arrBrow, arrResnicy, arrKombo,} from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'


export const Prices = () => {


    return (
        <section className={styles.main} id='Ceny'>
            <div className={styles.box}>
                <h2 className={styles.title}>Наши цены</h2>
                <Hint
                    title={'Маникюр'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrManicure.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Педикюр'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrPedikyur.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Брови'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrBrow.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Ресницы'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrResnicy.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
                <Hint
                    title={'Комбо (брови+ресницы)'}
                >
                <div className={styles.box_sale}>
                        <div className={styles.box_caput}>
                            <h5 className={styles.box_title}>Услуга</h5>
                            <h5 className={styles.box_title}>Стоимость</h5>
                        </div>
                        <ul className={styles.list}>
                        {arrKombo.map((item, index) => {
                                const keyUid = uuidv4()
                                if(index % 2 === 0){
                                    return(
                                        <li className={styles.item} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                } else {
                                    return(
                                        <li className={styles.item_grey} key={keyUid}>
                                            <div className={styles.item_box}>
                                                <p className={styles.text}>{item.officium}</p>
                                                <p className={styles.price}>{item.price}</p>
                                            </div>
                                            <p className={styles.description}>{item.description}</p>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>     
                </Hint>
            </div>
        </section>
    )
}