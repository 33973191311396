import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"
import icon from '../../image/Advantages/image_1.svg'
import icon_1 from '../../image/Advantages/image_2.svg'
import icon_2 from '../../image/Advantages/image_3.svg'
import icon_3 from '../../image/Advantages/image_4.svg'
import icon_4 from '../../image/Advantages/image_5.svg'
import icon_5 from '../../image/Advantages/image_6.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Advantages = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Advantages'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.overlay}>
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Почему выбирают нас?</motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>Лучшая косметика</h3>
                            <p className={styles.item_text}>В своей работе мы используем профессиональные косметические средства только от премиальных производителей.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon_1} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>ЭКОНОМИМ ВРЕМЯ</h3>
                            <p className={styles.item_text}>Мы стараемся максимально быстро и качественно выполнить процедуру, для того чтобы Вы как можно дольше наслаждались идеальным результатом</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon_2} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>высокая безопасность</h3>
                            <p className={styles.item_text}>Мы заботимся о здоровье и безопасности наших гостей, все инструменты проходят тщательную стерилизацию и дезинфекцию</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon_3} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>ПРОФЕССИОНАЛЬНАЯ КОМАНДА</h3>
                            <p className={styles.item_text}>Наши мастера проходят регулярные семинары и курсы повышения квалификации,водят новые техники и обучают молодых специалистов</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon_4} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>удобное расположение</h3>
                            <p className={styles.item_text}>Мы располагаемся рядом с центром города на улице  Корнилаева, до нас очень быстро и удобно добраться</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.item_box}>
                                <img className={styles.item_image}  src={icon_5} alt='иконка'/>
                            </div>
                            <h3 className={styles.item_title}>приятная атмосфера</h3>
                            <p className={styles.item_text}>Мы искренне рады видеть наших гостей и для их удобста мы поддерживаем в нашей студии приятную и комфортную атмосферу</p>
                        </li>
                    </motion.ul>
                </div>
                </div>
        </motion.section>
        
    )
}