import React from 'react'
import { motion } from "framer-motion"
import styles from './Training.module.scss'
import image from '../../image/Training/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const Training = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Курсы бровистов, лешмейкеров и мастеров макияжа.</h2>
                        <div className={styles.line}></div>
                        <p className={styles.text}>&#8195;Создатель курсов – практикующий brow и make up эксперт и одна из основательниц нашей студии- Софья Дунаева.</p>
                        <p className={styles.text}>&#8195; Обучение включают в себя обширную теоретическую часть и обязательную практику на моделях в нашей студии.</p>
                        <p className={styles.text}>&#8195;В своей программе Софья собрала максимум знаний и пользы, никакой лишней воды. Всё четко, качественно и по делу.</p>
                        <p className={styles.text}>&#8195;Обучение проходит как индивидуально, так и в формате мини группы (до 3-ёх человек).</p>
                        <p className={styles.text}>&#8195;После окончания обучения выдается сертификат мастера.</p>
                        <a className={styles.link}  href="https://dunaeva-school.ru/"  target='_blank' rel='noopener noreferrer'>подробнее</a>
                    </motion.div>
            </div>
        </motion.section>
        
        
    )
}